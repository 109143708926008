import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getListNested } from '../api/get-list-nested';
import { QUERY_KEYS } from '../constants';
import type { useGetGenericInfiniteListProps } from '../types';

export const useGetInfiniteList = <TInstance extends BaseInstance>(
  options: useGetGenericInfiniteListProps<TInstance> & {
    modelName: ModelName;
    config?: IBaseInstanceConfig<TInstance>;
  }
) =>
  useInfiniteQuery({
    ...options,
    queryKey: [
      options.modelName,
      QUERY_KEYS.LIST,
      {
        queryParams: options.queryParams,
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        ...options.queryParams,
      },
    ],
    queryFn: async ({ pageParam, signal }) => {
      const res = await getListNested<TInstance>({
        modelName: options.modelName,
        queryParams: {
          ...options.queryParams,
          page: pageParam,
        },
        nested: options?.nested,
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        signal,
      });
      return res;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta && lastPage.meta?.page >= lastPage.meta?.page_amount) {
        return undefined;
      }
      return (lastPage.meta?.page ?? 0) + 1;
    },
    refetchOnWindowFocus: false,
    initialPageParam: 1,
  });
