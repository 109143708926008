import { getList } from '@/requests/api/get-list';
import {
  type Apartment,
  type ApartmentRevenueIncreaseRow,
} from '@pigello/pigello-matrix';
import { chunkIds } from '../utils';
import type { TCustomList } from './types';

// Custom handler for Invoice
export async function handleCustomApartmentRevenueIncreaseRowNestedCase(
  listResponse: TCustomList<ApartmentRevenueIncreaseRow>
): Promise<TCustomList<ApartmentRevenueIncreaseRow>> {
  const sourceObjectIds = listResponse.data.reduce<Set<string>>(
    (acc, revenueIncreaseRow) => {
      if (revenueIncreaseRow.revenueRowSource?.space?.id) {
        acc.add(revenueIncreaseRow.revenueRowSource.space.id);
      }

      return acc;
    },
    new Set()
  );

  if (sourceObjectIds.size === 0) return listResponse;

  const chunkedSourceIds = chunkIds(sourceObjectIds);
  const resolvedSourcesPromises = await Promise.allSettled(
    chunkedSourceIds.map((chunk) =>
      getList<Apartment>({
        modelName: 'apartment',
        queryParams: {
          page: 1,
          pageSize: chunk.length,
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
        },
      })
    )
  );

  const resolvedSourceObjects: Apartment[] = [];
  const failedSourceObjects: unknown[] = [];
  for (const promise of resolvedSourcesPromises) {
    if (promise.status === 'fulfilled') {
      resolvedSourceObjects.push(...(promise.value.list ?? []));
    } else {
      failedSourceObjects.push(promise.reason);
    }
  }

  // Update revenue row with source object
  const mappedListResponse: TCustomList<ApartmentRevenueIncreaseRow> = {
    data: listResponse.data.map((revenueIncreaseRow) => ({
      ...revenueIncreaseRow,
      revenueRowSource: {
        ...revenueIncreaseRow.revenueRowSource,
        space: resolvedSourceObjects.find(
          (sourceObject) =>
            sourceObject.id === revenueIncreaseRow.revenueRowSource?.space?.id
        ),
      },
    })) as ApartmentRevenueIncreaseRow[],
  };

  return mappedListResponse;
}
