import type { ModelName } from '@pigello/pigello-matrix';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { getCount } from '../api/get-count';
import { QUERY_KEYS } from '../constants';
import type { ListArguments } from '../types';

export const useGetCount = (
  options: {
    modelName: ModelName;
    filters?: ListArguments['queryParams']['filters'];
    revalidate?: number;
    overrideUrl?: string;
    overrideUrlReplaceAll?: boolean;
    skipGlobalFilters?: boolean;
  } & Omit<UseQueryOptions<unknown, unknown, number>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: [
      options.modelName,
      QUERY_KEYS.COUNT,
      {
        ...options.filters,
        ...(options.overrideUrl && { overrideUrl: options.overrideUrl }),
        ...(options.overrideUrlReplaceAll && {
          overrideUrlReplaceAll: options.overrideUrlReplaceAll,
          skipGlobalFilters: options.skipGlobalFilters,
        }),
      },
    ],
    queryFn: async ({ signal }) => {
      const res = await getCount({
        overrideUrl: options.overrideUrl,
        overrideUrlReplaceAll: options.overrideUrlReplaceAll,
        modelName: options.modelName,
        filters: options.filters,
        skipGlobalFilters: options.skipGlobalFilters,
        signal,
      });
      return res;
    },
    refetchOnWindowFocus: false,
    retry: 1,
    ...options,
  });
};
