import { getList } from '@/requests/api/get-list';
import {
  type IndustrialPremises,
  type IndustrialPremisesRevenueIncreaseRow,
} from '@pigello/pigello-matrix';
import { chunkIds } from '../utils';
import type { TCustomList } from './types';

export async function handleCustomIndustrialPremisesRevenueIncreaseRowNestedCase(
  listResponse: TCustomList<IndustrialPremisesRevenueIncreaseRow>
): Promise<TCustomList<IndustrialPremisesRevenueIncreaseRow>> {
  const sourceObjectIds = listResponse.data.reduce<Set<string>>(
    (acc, revenueIncreaseRow) => {
      if (revenueIncreaseRow.revenueRowSource?.space?.id) {
        acc.add(revenueIncreaseRow.revenueRowSource.space.id);
      }
      return acc;
    },
    new Set()
  );

  if (sourceObjectIds.size === 0) return listResponse;

  const chunkedSourceIds = chunkIds(sourceObjectIds);
  const resolvedSourcesPromises = await Promise.allSettled(
    chunkedSourceIds.map((chunk) =>
      getList<IndustrialPremises>({
        modelName: 'industrialpremises',
        queryParams: {
          page: 1,
          pageSize: chunk.length,
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
        },
      })
    )
  );

  const resolvedSourceObjects: IndustrialPremises[] = [];
  const failedSourceObjects: unknown[] = [];
  for (const promise of resolvedSourcesPromises) {
    if (promise.status === 'fulfilled') {
      resolvedSourceObjects.push(...(promise.value.list ?? []));
    } else {
      failedSourceObjects.push(promise.reason);
    }
  }

  return {
    ...listResponse,
    data: listResponse.data.map((revenueIncreaseRow) => {
      const sourceSpaceId = revenueIncreaseRow.revenueRowSource?.space?.id;
      if (!sourceSpaceId || !revenueIncreaseRow.revenueRowSource?.id) {
        return revenueIncreaseRow;
      }

      const sourceSpace = resolvedSourceObjects.find(
        (space) => space.id === sourceSpaceId
      );
      if (!sourceSpace) return revenueIncreaseRow;

      return {
        ...revenueIncreaseRow,
        revenueRowSource: {
          ...revenueIncreaseRow.revenueRowSource,
          space: sourceSpace,
        },
      };
    }),
  };
}
