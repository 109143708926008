import { getConfig } from '@/lib/get-config';
import { type BaseInstance, type ModelName } from '@pigello/pigello-matrix';
import { fetchApi } from '.';
import { toInternalFieldNames } from '../instanceMapper';
import { buildBackendUrl, handleApiResponse } from './utils';

type Get = {
  modelName: ModelName;
  id?: string;
  revalidate?: number;
  overrideUrl?: string;
};

export async function get<T extends BaseInstance>({
  id,
  modelName,
  overrideUrl,
  revalidate,
}: Get) {
  const config = getConfig<ModelName, T>(modelName, true);

  if (!config) {
    throw new Error(`No config found for model ${modelName}`);
  }

  const backendUrl = buildBackendUrl(config, id, overrideUrl);

  const completeUrl = `${backendUrl}`;

  const res = await fetchApi({
    method: 'GET',
    next: {
      revalidate,
    },
    url: completeUrl,
  });

  const data = await handleApiResponse<T>(res);

  return toInternalFieldNames<T>(config, data);
}
