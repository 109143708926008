import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import {
  useMutation,
  useQueryClient,
  type UseMutationOptions,
} from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import type { ErrorResponse, useDeleteGenericInstanceProps } from '../types';

export const useDeleteGenericInstance = <Instance extends BaseInstance>(
  mutationFn: (
    options: useDeleteGenericInstanceProps<Instance>
  ) => Promise<Instance>,
  options: UseMutationOptions<Instance, ErrorResponse, unknown, unknown>,
  modelName: ModelName
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn,
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      // Invalidate the instance query
      queryClient.invalidateQueries({ queryKey: [modelName, variables?.id] });
      // Invalidate the tablelist query
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.LIST],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.ALL_INSTANCES],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.ALL_IDS],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.COUNT],
      });
    },
  });
};
