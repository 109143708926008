import { getConfig } from '@/lib/get-config';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { useQueries, useQuery } from '@tanstack/react-query';
import { get } from '../api/get';
import { getList } from '../api/get-list';
import { NEVER_FETCH_NESTED_FIELDS } from '../constants';
import type { useGetGenericInstanceProps } from '../types';
import { collectNestedIdsSingleInstance } from './utils';

/**
 * Props for the `useGetInstance` hook.
 */
interface UseGetInstanceProps<TInstance extends BaseInstance>
  extends useGetGenericInstanceProps<TInstance> {
  /** The name of the model to fetch. */
  modelName: ModelName;
}

/**
 * Custom React hook to fetch a single instance of a model along with its nested related data.
 *
 * @template TInstance - The type of the instance extending `BaseInstance`.
 *
 * @param {UseGetInstanceProps<TInstance>} options - Configuration options for fetching the instance.
 * @param {ModelName} options.modelName - The name of the model to fetch.
 * @param {string} options.id - The unique identifier of the instance to fetch.
 * @param {string[]} [options.nested=[]] - An array of nested field names to fetch related data.
 * @param {string} [options.overrideUrl] - An optional URL to override the default API endpoint.
 * @param {...any} [queryOptions] - Additional options to pass to the underlying React Query hooks.
 *
 * @returns {UseQueryResult<TInstance, Error>} - The result of the query including data, loading states, and error information.
 *
 * @throws {Error} If a specified nested field is not a relation field.
 *
 * @example
 * ```typescript
 * const { data, isLoading, isError, error } = useGetInstance({
 *   modelName: 'User',
 *   id: 'user123',
 *   nested: ['posts', 'comments'],
 * });
 * ```
 */
export const useGetInstance = <TInstance extends BaseInstance>(
  options: UseGetInstanceProps<TInstance> & {
    modelName: ModelName;
  }
) => {
  const {
    modelName,
    id,
    overrideUrl,
    nested = [],
    enabled,
    ...queryOptions
  } = options;

  // Retrieve configuration for the specified model
  const config = getConfig<ModelName, TInstance>(modelName, true);

  /**
   * Filters out nested fields that should never be fetched.
   *
   * @returns {Array<keyof TInstance>} - Filtered array of nested field names.
   */
  const filteredNested = nested.filter((fieldName) => {
    return !NEVER_FETCH_NESTED_FIELDS.find((n) => n === fieldName);
  });

  /**
   * Fetches the main instance data using React Query's `useQuery`.
   *
   * The query is enabled only if an `id` is provided.
   */
  const mainQuery = useQuery({
    queryKey: [modelName, id, overrideUrl],
    queryFn: async () =>
      await get({
        modelName,
        id,
        overrideUrl,
      }),
    enabled: enabled !== false && Boolean(id),
    ...queryOptions,
  });

  /**
   * Collects IDs from the specified nested fields in the main instance data.
   */
  const nestedIds = collectNestedIdsSingleInstance(
    mainQuery.data,
    filteredNested
  );

  /**
   * Determines which nested fields need to be fetched based on the collected IDs and configuration.
   *
   * @returns {Array<{ fieldName: keyof TInstance; ids: string[]; relationModelName: ModelName; type: string }>}
   * An array of objects containing information about each nested field to fetch.
   *
   * @throws {Error} If a nested field is not a relation field.
   */
  const nestedToFetch = !enabled
    ? []
    : (filteredNested
        .map((fieldName) => {
          const fieldConfig = config?.fields[fieldName];
          if (
            (fieldConfig && !('relationConfig' in fieldConfig)) ||
            !fieldConfig
          ) {
            throw new Error(
              `Field ${String(fieldName)} is not a relation field in model ${modelName}`
            );
          }
          const relationModelName = fieldConfig.relationConfig;
          const ids = nestedIds?.get(fieldName) ?? [];
          return {
            fieldName,
            ids,
            relationModelName,
            type: fieldConfig.type,
          };
        })
        .filter(({ ids }) => ids.length > 0) ?? []);

  /**
   * Uses React Query's `useQueries` to fetch all nested related data in parallel.
   */
  const nestedQueries = useQueries({
    queries: nestedToFetch.map(
      ({ fieldName, ids, relationModelName, type }) => ({
        queryKey: ['nested', fieldName, relationModelName, ids.join(',')],
        queryFn: async () => {
          const data = await getList<TInstance>({
            modelName: relationModelName,
            queryParams: {
              filters: {
                id: {
                  __in: ids.join(','),
                },
              },
              pageSize: ids.length,
            },
          });
          return {
            data: data.list,
            fieldName,
            type,
          };
        },
        enabled: enabled !== false && ids.length > 0,
      })
    ),
  });

  /**
   * Merges the fetched nested data into the main instance data.
   *
   * @returns {TInstance | undefined} - The merged data or undefined if the main query is not successful.
   */
  for (const nestedQuery of nestedQueries) {
    const { data: nestedData } = nestedQuery;
    if (nestedData?.data) {
      const { data, fieldName, type } = nestedData;
      const isManyRelation = type === 'manyrelation';
      const isRelation = type === 'relation';
      if (mainQuery.isSuccess) {
        if (isManyRelation) {
          mainQuery.data[fieldName] = data as TInstance[keyof TInstance];
        }
        if (isRelation && data.at(0)) {
          mainQuery.data[fieldName] = data[0] as TInstance[keyof TInstance];
        }
      }
    }
  }

  /**
   * Returns the combined query result including merged data and loading/error states.
   */
  return {
    data: mainQuery.data as TInstance,
    isLoading: mainQuery.isLoading,
    isFetching: mainQuery.isFetching,
    isPending: mainQuery.isPending,
    isError: mainQuery.isError,
    error: mainQuery.error,
    isSuccess: mainQuery.isSuccess,
    refetch: mainQuery.refetch,
  };
};
