import { useCreateGenericInstance } from './use-create-generic-instance';
import { useDeleteGenericInstance } from './use-delete-generic-instance';
import { useGetCount } from './use-get-count';
import { useGetInfiniteList } from './use-get-infinite-list';
import { useGetInstance } from './use-get-instance';
import { useGetList } from './use-get-list';
import { useMutateGenericInstance } from './use-mutate-generic-instance';

export {
  useCreateGenericInstance,
  useDeleteGenericInstance,
  useGetCount,
  useGetInfiniteList,
  useGetInstance,
  useGetList,
  useMutateGenericInstance,
};
