import { type ModelName } from '@pigello/pigello-matrix';
import { handleCustomInvoiceNestedCase } from '../custom-cases/invoice';
import { handleCustomNotificationNestedCase } from '../custom-cases/notification-distribution';
import { handleCustomApartmentContractRevenueIncreaseRowNestedCase } from './apartment-contract-revenue-increase-row';
import { handleCustomApartmentRevenueIncreaseRowNestedCase } from './apartment-revenue-increase-row';
import { handleCustomIndustrialPremisesRevenueIncreaseRowNestedCase } from './industrial-premises-revenue-increase-row';
import { handleCustomOutdoorSectionRevenueIncreaseRowNestedCase } from './outdoor-section-revenue-increase-row';
import { handleCustomParkingSpotRevenueIncreaseRowNestedCase } from './parking-spot-revenue-increase-row';

// Registry for custom case handlers
export type TCustomList<T> = { data: T[] };

// Handler functions type
type CustomCaseHandler<T = unknown> = (
  listResponse: TCustomList<T>
) => Promise<TCustomList<T>>;

export const customCaseHandlers: Partial<
  Record<ModelName, CustomCaseHandler<any>>
> = {
  invoice: handleCustomInvoiceNestedCase,
  notificationdistribution: handleCustomNotificationNestedCase,
  apartmentrevenueincreaserow:
    handleCustomApartmentRevenueIncreaseRowNestedCase,
  industrialpremisesrevenueincreaserow:
    handleCustomIndustrialPremisesRevenueIncreaseRowNestedCase,
  parkingspotrevenueincreaserow:
    handleCustomParkingSpotRevenueIncreaseRowNestedCase,
  outdoorsectionrevenueincreaserow:
    handleCustomOutdoorSectionRevenueIncreaseRowNestedCase,
  apartmentcontractrevenueincreaserow:
    handleCustomApartmentContractRevenueIncreaseRowNestedCase,
  industrialpremisescontractrevenueincreaserow:
    handleCustomIndustrialPremisesRevenueIncreaseRowNestedCase,
  parkingspotcontractrevenueincreaserow:
    handleCustomParkingSpotRevenueIncreaseRowNestedCase,
  outdoorsectioncontractrevenueincreaserow:
    handleCustomOutdoorSectionRevenueIncreaseRowNestedCase,
} as const;
